.div-dibujo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    svg {
        margin-top: 10px;
        width: 80%;
    }
    .botones{
        margin-top: 30px;
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        .btn{
            img{
                width: 200px;
            }
        }
    
        .btn:hover{
            cursor: pointer;
        }
    }
}

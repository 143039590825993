.contenido_premio{
    width: 702px;
    height: 548px;
    position: absolute;
    margin-top: 50px;

    display: flex;
    flex-direction: column;
    align-items: center;
    .fondo{
        width: 100%;
        border: 5px solid #FFFF00;
        border-radius: 20px;
    }

    .titulo{
        width: 450px;
        margin-top: -530px;
    }

    .premio{
        margin-top: -50px;
        width: 250px;
    }

    .btn_cerrar{
        width: 70px;
        margin-top: 30px;

        &:hover{
            cursor: pointer;            
            opacity: 0.5;
        }
    }
}